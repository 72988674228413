@font-face {
  font-family: 'Robotobold';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'RobotoMedium';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'RobotoRegular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Roboto-Regular_0.ttf');
}

@font-face {
  font-family: 'RobotoLight';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Roboto-Light.ttf');
}

.heroImage {
  background-position: left!important;
}

.quoteWrapper {
  color: #fff;
  font-family: "RobotoRegular";
  font-size: 19px;
  background: url("./Images/Quote-patch.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.videosWrapper h4 {
  color: #005495;
  font-family: "RobotoMedium";
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% ;
  padding: 5px;
  border: 5px solid #005495;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 250px!important;
}

.aboutContent {
  width: 80%;
}

.aboutTitle {
  color: #E20011;
  font-size: 40px;
  font-family: "RobotoMedium";
  padding-right: 20px;
}

.aboutContent p {
  color: #000;
  font-size: 18px;
  font-family: "RobotoRegular";
}

.mainBlue {
  color: #E20011;
}

.justify-content-end h4, .justify-content-end h6 {
  text-align: right;
}

footer a {
  color: #26A9E0;
}
@media only screen and (max-width:1599.91px) {
  .aboutTitle {
    font-size: 32px;
  }
  .quoteWrapper {
    background: url("./Images/Quote-patch-lg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width:1299.91px) {
  .aboutContent {
    width: 90%;
  }
  
  .aboutTitle {
    font-size: 26px;
  }

  .quoteWrapper {
    background: url("./Images/Quote-patch-md.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}


@media only screen and (max-width:999.91px) {
  .quoteWrapper {
    background: url("./Images/red-patch.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media only screen and (max-width:767.91px) {
  .contact-section .contact-form {
      padding: 2rem;
      margin: 2rem 0rem;
      border: none;
  } 
  .contact-form .clrd-btn {
      border-radius: 12px;
      font-size: 36px;
  }

  .quoteWrapper {
    font-size: 18px;
  }

  .quoteWrapper h4 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width:499.91px) {
  .contact-section .contact-form {
      padding: 1.25rem;
      margin: 2rem 0rem;
  }

  .contact-section h4 {
      font-size: 36px;
  }

  .contact-section .contact-form .tcpa {
      font-size: 12px;
      line-height: 15px;
  }

  .contact-form .clrd-btn {
      font-size: 24px;
      padding: .25rem 3.5rem;
  }
}

@media only screen and (max-width:399.91px) {
  .contact-section h4 {
      font-size: 32px;
  }
  .contact-form .clrd-btn {
      font-size: 20px;
      padding: .25rem 2.5rem;
  }
}