.contact-section h4 {
    font-size: 44px;
    font-family: 'Robotobold';
}

.contact-section .contact-form {
    border: 2px solid #b3b0b0;
    border-radius: 24px;
    padding: 2.25rem;
    margin: 2rem;
}

.contact-section .contact-form h5 {
    color: #E20011;
    font-size: 24px;
    font-family: "RobotoMedium";
}

.contact-section .contact-form h6 {
    color: #000000;
    font-size: 22px;
    font-family: "RobotoRegular";
}

.contact-section .contact-form label {
    font-size: 18px;
    font-family: "RobotoMedium";
}

.contact-section .contact-form textarea {
    resize: none!important;
}

.contact-section .form-control {
    margin-bottom: 1rem;
}

.contact-section .contact-form .tcpa {
    Color : #000;
    font-size: 14px;
    font-family: 'RobotoRegular';
    text-align: justify;
}

.contact-form .tcpa a {
    color: #26A9E0;
}

.contact-form .clrd-btn {
    background: #E20011 0% 0% no-repeat padding-box;
    cursor: pointer;
    border-color: transparent;
    font-size: 32px;
    font-family: 'Robotobold';
    padding: .25rem 2rem;
}

.contact-form .clrd-btn:focus {
    outline: none;
}

.contact-form  .clrd-btn:hover {
    text-decoration: none;
    background: #e0313e  0% 0% no-repeat padding-box;
  }

  .spinner-border {
      vertical-align: middle;
  }

.filename-trim{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.custom-file-input:lang(en)~.custom-file-label::after{
    background-color: #E20011;
    color: #fff;
}

.toast {
    max-width: 100%;
}

.toast-header {
    color: #ffffff;
    background-color: rgb(0 0 0 / 0.75);
    border-radius: calc(.25rem - 1px);
}

.form-control.is-valid, .was-validated .form-control:valid {
    background-image: none!important;
}

  @media only screen and (max-width:767.91px) {
    .contact-section .contact-form {
        padding: 2rem;
        margin: 2rem 0rem;
        border: none;
    } 
    .contact-form .clrd-btn {
        border-radius: 12px;
        font-size: 36px;
    }
  }

  @media only screen and (max-width:499.91px) {
    .contact-section .contact-form {
        padding: 1.25rem;
        margin: 1rem 0rem;
    }

    .contact-section h4 {
        font-size: 36px;
    }

    .contact-section .contact-form .tcpa {
        font-size: 12px;
        line-height: 15px;
    }

    .contact-form .clrd-btn {
        font-size: 24px;
        padding: .25rem 3.5rem;
    }

    .contact-section .contact-form h5 {
        font-size: 20px;
    }
    
    .contact-section .contact-form h6 {
        font-size: 18px;
    }

    .contact-section .contact-form label {
        font-size: 16px;
        font-family: "RobotoMedium";
    }
  }

  @media only screen and (max-width:399.91px) {
    .contact-section h4 {
        font-size: 32px;
    }
    .contact-form .clrd-btn {
        font-size: 20px;
        padding: .25rem 2.5rem;
    }
  }